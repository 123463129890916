import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app';
import './assets/fonts/NeueMontreal-Regular.ttf';
import './locales/i18n';
import './styles/font.css';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<BrowserRouter>
		<Suspense fallback="...is loading">
			<App />
		</Suspense>
	</BrowserRouter>
);
