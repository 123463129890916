import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1.8rem;
`;

export const SearchInput = styled.input`
	border-radius: 7rem;
	border: 1px solid ${({ theme }) => theme.colors.bright_grey};
	padding: 0.7rem 1rem;
	width: 100%;
`;

export const CancelButton = styled.a`
	font-size: 0.9rem;
	font-family: 'NeueMontreal';
	color: ${({ theme }) => theme.colors.bright_blue};
	text-decoration: none;
	margin: 0 0.5rem 0 1rem;
	cursor: pointer;
`;
