import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 17rem;
	position: relative;
	display: flex;
`;

export const ContentContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: flex-end;
	padding: 0.8rem;
	z-index: 9;
	padding-bottom: 1.6rem;
`;

export const Title = styled.h1`
	font-size: 2.5rem;
	color: ${({ theme }) => theme.colors.white};
`;

export const Description = styled.h1`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.colors.white};
	margin-top: 1rem;
	margin-bottom: 2rem;
`;

export const BottomContent = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const IconContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Icon = styled.img`
	width: 1.2rem;
	height: 1.2rem;
	margin-right: 0.5rem;
`;

export const IconText = styled.span`
	font-size: 1.1rem;
	color: ${({ theme }) => theme.colors.white};
`;

export const BackgroundImage = styled.img`
	width: 100%;
	height: 17rem;
	position: absolute;
	z-index: 1;
`;

export const BackgroundImageOpacity = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 2;
`;
