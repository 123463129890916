import styled from 'styled-components';
import { CarouselDotsProps } from '.';

export const Container = styled.div<CarouselDotsProps>`
	width: 0.6rem;
	height: 0.6rem;
	border-radius: 0.3rem;
	background-color: ${({ theme, active }) =>
		active ? theme.colors.bright_pink : theme.colors.grey};
	margin-left: 0.5rem;
`;
