import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	background-color: ${({ theme }) => theme.colors.white};
	align-items: flex-end;
	position: fixed;
	bottom: 0;
	overflow-y: hidden;
`;

export const QRCodeContainer = styled.div`
	display: flex;
	width: 100%;
	height: 75%;
	background-color: ${({ theme }) => theme.colors.bright_pink};
	align-items: center;
	flex-direction: column;
	border-top-left-radius: 2rem;
	border-top-right-radius: 2rem;
	padding: 1.5rem;
`;

export const Subtitle = styled.p`
	font-size: 1.05rem;
	color: ${({ theme }) => theme.colors.black};
	text-align: center;
	margin-top: 1rem;
`;

export const Button = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	justify-self: flex-end;
	align-items: center;
	padding: 1.1rem;
	border-radius: 9rem;
	border: 1px solid ${({ theme }) => theme.colors.black};
	margin-top: 3rem;
	background-color: ${({ theme }) => theme.colors.bright_pink};
`;

export const ButtonText = styled.span`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.colors.black};
`;
