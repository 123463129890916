import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { CancelButton, Container, SearchInput } from './styles';

interface SearchHeaderProps {
	handleChangeSearchText: (event: FormEvent<HTMLInputElement>) => void;
}

export const SearchHeader = ({ handleChangeSearchText }: SearchHeaderProps) => {
	const navigate = useNavigate();

	const handleNavigateBack = () => {
		navigate(-1);
	};

	return (
		<Container>
			<SearchInput
				onChange={handleChangeSearchText}
				placeholder="Search  in Snap it..."
			/>
			<CancelButton onClick={handleNavigateBack}>Cancel</CancelButton>
		</Container>
	);
};
