import HamburgerMenuIcon from 'assets/icons/hamburger';
import SnapItIcon from 'assets/icons/snapit-logo';

import useGreetings from 'hooks/useGreetings';

import theme from 'styles/theme';

import ListItems from './burgerItems';
import {
	Container,
	Disclaimer,
	Footer,
	GreetingsText,
	HeaderMenu,
	Item,
	ItemIcon,
	ItemName,
	ItemsList,
	Menu,
	TopWrapper,
} from './styles';

interface IHamburgerMenuProps {
	isOpen: boolean;
	handleOpenHamburgerMenu: () => void;
}

export const HamburgerMenu = ({
	isOpen,
	handleOpenHamburgerMenu,
}: IHamburgerMenuProps) => {
	const greetings = useGreetings();

	return (
		<Container isOpen={isOpen}>
			<Menu>
				<TopWrapper>
					<HeaderMenu>
						<GreetingsText>
							{greetings}, <br /> Ursula Kunde
						</GreetingsText>
						<HamburgerMenuIcon
							onClick={handleOpenHamburgerMenu}
							color={theme.colors.black}
							size={32}
						/>
					</HeaderMenu>

					<ItemsList>
						{ListItems.map((item) => (
							<Item key={item.name}>
								<ItemIcon src={item.icon} />
								<ItemName>{item.name}</ItemName>
							</Item>
						))}
					</ItemsList>
				</TopWrapper>

				<Footer>
					<SnapItIcon size={40} color={theme.colors.bright_pink} />
					<Disclaimer>Built with pride 🏳️‍🌈</Disclaimer>
				</Footer>
			</Menu>
		</Container>
	);
};
