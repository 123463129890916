import { ChangeEvent, useState } from 'react';

import { useDebounce } from 'hooks/useDebounce';
import { useProducts } from 'hooks/useProducts';

import { Product } from 'types/products';

import { SearchHeader } from './components/header';
import { QuickSuggestions } from './components/quick-suggestions';
import { Results } from './components/results';
import { Container } from './styles';

export const Search = () => {
	const [searchText, setSearchText] = useState('');
	const [selectedSuggestion, setSelectedSuggestion] = useState('');
	const [dishResults, setDishResults] = useState<Product[]>([]);

	const { data } = useProducts();

	const handleChangeSearchText = (ev: ChangeEvent<HTMLInputElement>) => {
		setSelectedSuggestion('');
		setSearchText(ev.target.value);
		if (data) {
			const filteredProducts: Product[] = data.filter((dish) =>
				dish.name.includes(ev.target.value)
			);
			setDishResults(filteredProducts);
		}
	};

	const changeText = useDebounce(handleChangeSearchText);

	const handleSelectedSugestion = (suggestion: string) => {
		if (suggestion === selectedSuggestion) {
			setSelectedSuggestion('');
			if (data) {
				setDishResults(data);
			}
			return;
		}
		if (data) {
			setDishResults(data);
		}
		setSelectedSuggestion(suggestion);
	};

	return (
		<Container>
			<SearchHeader handleChangeSearchText={changeText} />
			{!searchText && (
				<QuickSuggestions
					handleSelectedSugestion={handleSelectedSugestion}
					selectedSuggestion={selectedSuggestion}
				/>
			)}
			{dishResults.length > 0 && <Results dishes={dishResults} />}
		</Container>
	);
};
