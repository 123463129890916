import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useTableVerification } from 'contexts/table';

import api from 'services/api';

import { Table } from 'types/table';

import { Container, GotoMenuButton, NameField, Title } from './styles';

interface language {
	nativeName: string;
}

interface languages {
	en: language;
	de: language;
	pt: language;
}

export const Welcome = () => {
	const [name, setName] = useState('');
	const { table } = useTableVerification();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();

	const lngs: languages = {
		en: { nativeName: 'English' },
		de: { nativeName: 'Deutsch' },
		pt: { nativeName: 'Portuguese' },
	};

	const handleGoToMenu = () => {
		navigate('/dashboard', { state: name });
	};
	return (
		<Container>
			<>
				<div>
					{Object.keys(lngs).map((lng) => (
						<button
							key={lng}
							style={{
								fontWeight:
									i18n.resolvedLanguage === lng
										? 'bold'
										: 'normal',
							}}
							type="submit"
							onClick={() => i18n.changeLanguage(lng)}
						>
							{lngs[lng as keyof languages].nativeName}
						</button>
					))}
				</div>
				<Title>{t('welcome.whatsYourName')}</Title>
				<NameField
					onChange={(e) => {
						setName(e.currentTarget.value);
					}}
				/>
				<GotoMenuButton type="button" onClick={handleGoToMenu}>
					{t('welcome.startOrder')}
				</GotoMenuButton>
			</>
		</Container>
	);
};
