import styled from 'styled-components';

interface ContainerProps {
	backgroundColor: string;
	isFixed: boolean;
}

export const Container = styled.div<ContainerProps>`
	width: 100vw;
	padding: 0.8rem;
	background-color: ${({ backgroundColor }) => backgroundColor};
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: ${({ isFixed }) => (isFixed ? 'fixed' : 'absolute')};
	top: 0;
	z-index: 999;
`;

export const IconButton = styled.div``;

export const Logo = styled.img`
	width: 9rem;
	height: 2.5rem;
`;

export const Icon = styled.img`
	width: 32px;
	height: 32px;
`;
