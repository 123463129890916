import { useQuery } from 'react-query';

import { useTableVerification } from 'contexts/table';

import api from 'services/api';

export const getEstablishmentOverview = async (id?: string): Promise<any> => {
	const { data } = await api.get(
		`/establishments/${id}/establishmentOverview`
	);
	return data;
};

export const useEstablishmentOverview = () => {
	const { table } = useTableVerification();
	return useQuery(
		['establishment'],
		() => getEstablishmentOverview(table?.establishment_id),
		{
			staleTime: 1000 * 60 * 120,
		}
	);
};
