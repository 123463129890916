import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const Title = styled.h1`
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.black};
	margin: 1.5rem 0 1rem 1rem;
`;

export const ItemsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
