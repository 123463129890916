import { useQuery } from 'react-query';

import { useTableVerification } from 'contexts/table';

import api from 'services/api';

import { Category } from 'types/category';

export const getCategories = async (
	establishment_id: string
): Promise<Category[]> => {
	const { data } = await api.get(
		`establishments/${establishment_id}/categories`
	);
	return data;
};

export const useCategories = () => {
	const { table } = useTableVerification();
	return useQuery(
		['categories'],
		() => getCategories(table.establishment_id),
		{
			staleTime: 1000 * 60 * 120,
		}
	);
};
