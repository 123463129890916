import { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useNavigate } from 'react-router-dom';

import { useTableVerification } from 'contexts/table';

import {
	Button,
	ButtonText,
	Container,
	QRCodeContainer,
	Subtitle,
} from './styles';

export const QRCode = () => {
	const [data, setData] = useState('');
	const [error, setError] = useState(false);
	const { verifyTable, removeTableFromLocalStorage } = useTableVerification();
	const navigate = useNavigate();

	const handleNavigateToWelcome = async (data: string) => {
		const isTableVerified = await verifyTable(data);
		console.log(isTableVerified, 'isTableVerified');
		if (isTableVerified) {
			navigate(`/welcome/${isTableVerified.id}`);
		} else {
			setError(true);
		}
	};

	return (
		<>
			<Container>
				<QRCodeContainer>
					<QrReader
						constraints={{ facingMode: 'environment' }}
						onResult={async (result, error) => {
							removeTableFromLocalStorage();
							if (!!result) {
								await handleNavigateToWelcome(result.getText());
							}

							if (!!error) {
								console.info(error);
							}
						}}
						videoContainerStyle={{
							height: 220,
							width: 180,
							paddingTop: 0,
						}}
						videoStyle={{
							width: 200,
							height: 180,
						}}
					/>
					<Subtitle>
						Scan the QR code to have access to <br /> wonderful
						options
					</Subtitle>

					{error && (
						<Subtitle>
							Invalid table, contact the waiter, please!
						</Subtitle>
					)}

					<Button>
						<ButtonText>About snap it</ButtonText>
					</Button>
				</QRCodeContainer>
			</Container>
		</>
	);
};
