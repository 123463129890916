import styled from 'styled-components';

interface SuggestionProps {
	isSelected: boolean;
}

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const Title = styled.h1`
	font-size: 1.2rem;
`;

export const TagsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
`;

export const Tag = styled.div<SuggestionProps>`
	padding: 0.5rem 1rem;
	border: 1px solid ${({ theme }) => theme.colors.bright_pink};
	justify-content: center;
	align-items: center;
	border-radius: 3rem;
	margin-right: 0.5rem;
	margin-top: 1rem;
	background-color: ${({ theme, isSelected }) =>
		isSelected ? theme.colors.bright_pink : theme.colors.white};
`;

export const TagText = styled.span<SuggestionProps>`
	font-size: 0.9rem;
	color: ${({ theme, isSelected }) =>
		isSelected ? theme.colors.white : theme.colors.bright_pink};
`;
