import { useEffect, useState } from 'react';

import { Footer } from 'components/footer';
import { Header } from 'components/header';

import { useCategories } from 'hooks/useCategories';
import { useProducts } from 'hooks/useProducts';

import { useCart } from 'contexts/cart';

import theme from 'styles/theme';

import { Category } from 'types/category';
import { Product } from 'types/products';

import { Categories } from './components/categories';
import { Groups } from './components/groups';
import { Container } from './styles';

export const Menu = () => {
	const { data, error, isLoading } = useCategories();
	const [selectedCategory, setSelectedCategory] = useState('');
	const [products, setProducts] = useState<Product[]>();

	useEffect(() => {
		if (data) {
			setSelectedCategory(data[0].name);
		}
	}, [data]);

	const handleChangeCategory = (category: Category) => {
		setSelectedCategory(category.name);
		setProducts(category.products);
	};

	const RenderMenu = () => {
		if (isLoading) {
			return null;
		}

		if (error) {
			return null;
		}

		if (products) {
			return <Groups products={products} />;
		}

		return null;
	};

	return (
		<Container>
			<Header
				backgroundColor={theme.colors.light_grey}
				isFixed
				iconColor={theme.colors.black}
				logoColor={theme.colors.bright_pink}
				logoSize={40}
			/>
			{data && (
				<>
					<Categories
						categories={data}
						handleChangeCategory={handleChangeCategory}
						selectedCategory={selectedCategory}
					/>
					<RenderMenu />
				</>
			)}
			<Footer />
		</Container>
	);
};
