import Carousel from 'react-multi-carousel';

import { CarouselDots } from 'components/carousel-dots';
import { DishCarousel } from 'components/dish-carousel';

import { useProducts } from 'hooks/useProducts';

import { Container, ItemsContainer, Title } from './styles';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 4,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

export const LastMinuteCombinations = () => {
	const { data } = useProducts();
	console.log(data);
	return (
		<Container>
			<Title>Last minute combinations</Title>

			{data ? (
				<Carousel
					responsive={responsive}
					showDots
					autoPlay
					autoPlaySpeed={3000}
					arrows={false}
					infinite
					rewind={false}
					rewindWithAnimation={false}
					additionalTransfrom={0}
					customDot={<CarouselDots />}
				>
					{data.map((dish) => (
						<ItemsContainer key={dish.id}>
							<DishCarousel dish={dish} />
						</ItemsContainer>
					))}
				</Carousel>
			) : null}
		</Container>
	);
};
