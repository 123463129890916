interface HamburgerMenuProps {
	size: number;
	color: string;
	fill: string;
}

export const HomeIcon = ({
	size,
	color,
	fill = 'none',
}: HamburgerMenuProps) => {
	return (
		<svg
			width={size}
			height={size + 1}
			viewBox={`0 0 ${size} ${size + 1}`}
			fill={fill}
		>
			<path
				d="M1.94189 27V11.6545L13.7752 1.32727L25.6086 11.6545V27H18.4419V20.5333C18.4419 17.9362 16.3695 15.8 13.7752 15.8C11.181 15.8 9.10856 17.9362 9.10856 20.5333V27L1.94189 27Z"
				stroke={color}
				stroke-width="2"
			/>
		</svg>
	);
};
