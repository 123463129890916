import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Cart } from 'screens/cart';
import { Dashboard } from 'screens/dashboard';
import { Menu } from 'screens/menu';
import { QRCode } from 'screens/qr-code';
import { Search } from 'screens/search';
import { Splash } from 'screens/splash';
import { Welcome } from 'screens/welcome';

import { PrivateRoutes } from './private-routes';

const Navigation = () => {
	return (
		<Routes>
			<Route element={<PrivateRoutes />}>
				<Route element={<Welcome />} path="/welcome/:tableId" />
				<Route element={<Menu />} path="/menu" />
				<Route element={<Dashboard />} path="/dashboard" />
				<Route element={<Cart />} path="/cart" />
				<Route element={<Search />} path="/search" />
			</Route>

			<Route element={<Splash />} path="/" />
			<Route element={<QRCode />} path="/scanQrCode" />
		</Routes>
	);
};

export default Navigation;
