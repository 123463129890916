import { ThemeProvider } from 'styled-components';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { CartContextProvider } from 'contexts/cart';
import { TableVerificationProvider } from 'contexts/table';

import { makeServer } from 'services/mirage';

import Navigation from './routes/route';
import { GlobalStyle } from './styles/global';
import theme from './styles/theme';

if (process.env.NODE_ENV === 'development') {
	//makeServer();
}

const queryClient = new QueryClient();
const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider theme={theme}>
				<TableVerificationProvider>
					<CartContextProvider>
						<Navigation />
					</CartContextProvider>
				</TableVerificationProvider>
				<GlobalStyle />
			</ThemeProvider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	);
};

export default App;
