import CarouselImage from 'assets/CarouselImage.png';
import Award from 'assets/award.svg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
	AwardIcon,
	CarouselBackgroundImage,
	CarouselItemContainer,
	Circle,
	Container,
	ContentWrapper,
	DishName,
	FooterWrapper,
	SetFoodIcon,
	TextWrapper,
	Title,
	WelcomeText,
} from './styles';

const WelcomeConfigResponsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1, // optional, default to 1.
	},
};

export const Promotions = () => {
	return (
		<Container>
			<WelcomeText>Current promotions</WelcomeText>
			<Carousel
				responsive={WelcomeConfigResponsive}
				autoPlay
				infinite
				arrows={false}
				showDots={false}
				autoPlaySpeed={3000}
			>
				<CarouselItemContainer>
					<CarouselBackgroundImage src={CarouselImage} />
					<ContentWrapper>
						<SetFoodIcon />

						<FooterWrapper>
							<TextWrapper>
								<Title>To try out</Title>
								<DishName>Veggie brunch</DishName>
							</TextWrapper>
							<Circle>
								<AwardIcon src={Award} />
							</Circle>
						</FooterWrapper>
					</ContentWrapper>
				</CarouselItemContainer>
				<CarouselItemContainer>
					<CarouselBackgroundImage src={CarouselImage} />
					<ContentWrapper>
						<SetFoodIcon />

						<FooterWrapper>
							<TextWrapper>
								<Title>To try out</Title>
								<DishName>Veggie brunch</DishName>
							</TextWrapper>
							<Circle>
								<AwardIcon src={Award} />
							</Circle>
						</FooterWrapper>
					</ContentWrapper>
				</CarouselItemContainer>
			</Carousel>
		</Container>
	);
};
