import { Product } from 'types/products';

import {
	ItemCard,
	ItemImage,
	ItemIngredients,
	ItemName,
	ItemPrice,
	ItemTextWrapper,
} from './styles';

interface DishCarouselProps {
	dish: Product;
}

export const DishCarousel = ({ dish }: DishCarouselProps) => {
	const { name, description, price, photo_url } = dish;
	return (
		<ItemCard>
			<ItemTextWrapper>
				<ItemName>{name}</ItemName>
				<ItemIngredients>{description}</ItemIngredients>
				<ItemPrice>€ {price}</ItemPrice>
			</ItemTextWrapper>
			<ItemImage src={photo_url} alt="Dish photo" />
		</ItemCard>
	);
};
