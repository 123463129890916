/* eslint-disable import/prefer-default-export */
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

    * {
        box-sizing: border-box;
        margin: 0px;
        padding: 0px;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75%;
        }

        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }

    body {
        -webkit-font-smoothing: antialised;
        height: 100%;
    }

    h1, h2, h3, h4, h5, span, p {
        font-family: NeueMontreal;
        font-weight: 400;
    }  

    html, body{
        width: 100%;
        height: 100%;
        overflow-x: hidden; 
  }
    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }
`;
