import { useEffect, useState } from 'react';

const useGreetings = () => {
	const [hour, setHour] = useState(0);

	useEffect(() => {
		const now = new Date().getHours();
		setHour(now);
	}, []);

	if (hour < 12) {
		return 'Good morning';
	} else if (hour >= 12 && hour < 18) {
		return 'Good afternoon';
	} else return 'Good evening';
};

export default useGreetings;
