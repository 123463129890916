import HamburgerMenuIcon from 'assets/icons/hamburger';
import SearchIcon from 'assets/icons/search';
import SnapItIcon from 'assets/icons/snapit-logo';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { HamburgerMenu } from '../hamburgerMenu';
import { Container, IconButton } from './styles';

interface HeaderProps {
	backgroundColor: string;
	isFixed: boolean;
	iconColor: string;
	logoColor: string;
	logoSize: number;
}

export const Header = ({
	backgroundColor,
	isFixed,
	iconColor,
	logoColor,
	logoSize,
}: HeaderProps) => {
	const [openHamburgerMenu, setOpenHamburgerMenu] = useState(false);
	const navigate = useNavigate();
	const handleOpenHamburgerMenu = useCallback(() => {
		setOpenHamburgerMenu((prevState) => !prevState);
	}, []);

	const navigateToSearch = () => {
		navigate('/search');
	};

	return (
		<Container backgroundColor={backgroundColor} isFixed={isFixed}>
			<>
				<IconButton onClick={handleOpenHamburgerMenu}>
					<HamburgerMenuIcon
						color={iconColor}
						size={32}
						onClick={handleOpenHamburgerMenu}
					/>
				</IconButton>
				<SnapItIcon color={logoColor} size={logoSize} />
				<IconButton onClick={navigateToSearch}>
					<SearchIcon color={iconColor} size={32} />
				</IconButton>
			</>
			<HamburgerMenu
				isOpen={openHamburgerMenu}
				handleOpenHamburgerMenu={handleOpenHamburgerMenu}
			/>
		</Container>
	);
};
