import styled from 'styled-components';

export interface CategoryTagProps {
	isSelected: boolean;
}

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;

export const Title = styled.h1`
	font-size: 2rem;
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 1rem;
`;

export const CategoryContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
`;

export const CategoryWrapper = styled.div<CategoryTagProps>`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1rem;
	border-radius: 0.2rem;
	margin-right: 0.5rem;
	background-color: ${({ theme, isSelected }) =>
		isSelected ? theme.colors.bright_pink : theme.colors.bright_grey};
`;

export const CategoryText = styled.p<CategoryTagProps>`
	font-size: 0.9rem;
	color: ${({ theme, isSelected }) =>
		isSelected ? theme.colors.white : theme.colors.black};
`;
