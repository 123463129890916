import { useCart } from 'contexts/cart';

import { Product } from 'types/products';

import {
	ItemCard,
	ItemImage,
	ItemIngredients,
	ItemName,
	ItemPrice,
	ItemTextWrapper,
} from './styles';

export interface DishProps {
	isSelected: boolean;
	dish: Product;
}

export const Dish = ({ isSelected, dish }: DishProps) => {
	const { name, description, price, photo_url } = dish;
	const { addToCart } = useCart();

	return (
		<ItemCard isSelected={isSelected} onClick={() => addToCart(dish)}>
			<ItemTextWrapper>
				<ItemName>{name}</ItemName>
				<ItemIngredients>{description}</ItemIngredients>
				<ItemPrice>€ {price}</ItemPrice>
			</ItemTextWrapper>
			<ItemImage src={photo_url} alt={name} />
		</ItemCard>
	);
};
