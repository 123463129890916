import { Container, Tag, TagsContainer, TagText, Title } from './styles';

const suggestions = [
	'Starter',
	'Main dish',
	'Desert',
	'Kids',
	'Trend',
	'Plant based',
	'Seasonal',
	'Drinks',
	'Soft drinks',
	'Coffee',
	'Tea',
];

interface QuickSuggestionsProps {
	handleSelectedSugestion: (suggestion: string) => void;
	selectedSuggestion: string;
}

export const QuickSuggestions = ({
	handleSelectedSugestion,
	selectedSuggestion,
}: QuickSuggestionsProps) => {
	return (
		<Container>
			<Title>Quick suggestions</Title>
			<TagsContainer>
				{suggestions.map((item) => {
					const isSelected = selectedSuggestion === item;
					return (
						<Tag
							key={item}
							isSelected={isSelected}
							onClick={() => handleSelectedSugestion(item)}
						>
							<TagText isSelected={isSelected}>{item}</TagText>
						</Tag>
					);
				})}
			</TagsContainer>
		</Container>
	);
};
