import { DishCarousel } from 'components/dish-carousel';

import { Product } from 'types/products';

import { Container, Title } from './styles';

interface ResultsProps {
	dishes: Product[];
}

export const Results = ({ dishes }: ResultsProps) => {
	return (
		<Container>
			<Title>Results ({dishes.length})</Title>
			{dishes.map((dish) => (
				<DishCarousel dish={dish} />
			))}
		</Container>
	);
};
