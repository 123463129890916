import { useCart } from 'contexts/cart';

import {
	Container,
	Item,
	ItemImage,
	ItemInfo,
	ItemName,
	ItemPrice,
	ItemsAmount,
	ItemsAmountText,
	LeftContent,
	Title,
} from './styles';

export const OrderItems = () => {
	const { cart } = useCart();
	return (
		<Container>
			<Title>Order items</Title>
			{cart
				? cart.map((item) => {
						return (
							<Item key={item.product.id}>
								<LeftContent>
									<ItemsAmount>
										<ItemsAmountText>
											{item.quantity}
										</ItemsAmountText>
									</ItemsAmount>
									<ItemInfo>
										<ItemName>{item.product.name}</ItemName>
										<ItemPrice>
											€ {item.product.price}{' '}
										</ItemPrice>
									</ItemInfo>
								</LeftContent>
								<ItemImage
									src={item.product.photo_url}
									alt={item.product.name}
								/>
							</Item>
						);
				  })
				: null}
		</Container>
	);
};
