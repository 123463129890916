export default {
	colors: {
		background: '#F4F4F4',
		black: '#000000',
		blue: '#3568DC',
		bright_blue: '#382FFF',
		bright_grey: '#EFEFEF',
		bright_pink: '#FA4B68',
		grey: '#D9D9D9',
		light_grey: '#F4F4F4',
		orange: '#E06552',
		yellow: '#F6DC5C',
		white: '#FFFFFF',
	},
	fonts: {
		regular: 'Montserrat_400Regular',
		medium: 'Montserrat_500Medium',
		semibold: 'Montserrat_600SemiBold',
		bold: 'Montserrat_700Bold',
	},
};
