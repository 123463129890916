import { Dish } from 'components/dish';

import { useCart } from 'contexts/cart';

import { Product } from 'types/products';

import { Container, ItemsContainer, Title } from './styles';

interface GroupsProps {
	products: Product[];
}

export const Groups = ({ products }: GroupsProps) => {
	const { isOnCart } = useCart();
	return (
		<Container>
			<Title>Bread & Snacks</Title>

			<ItemsContainer>
				{products.map((item) => (
					<Dish key={item.id} isSelected={isOnCart(item)} dish={item} />
				))}
			</ItemsContainer>
		</Container>
	);
};
