import { useQuery } from 'react-query';

import { useTableVerification } from 'contexts/table';

import api from 'services/api';

import { Product } from 'types/products';

export const getProducts = async (
	establishment_id: string
): Promise<Product[]> => {
	const { data } = await api.get(
		`establishments/${establishment_id}/products`
	);
	return data;
};

export const useProducts = () => {
	const { table } = useTableVerification();
	return useQuery(['products'], () => getProducts(table.establishment_id), {
		staleTime: 1000 * 60 * 120,
	});
};
