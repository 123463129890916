import LogoSnapIt from 'assets/logo-snapit.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTableVerification } from 'contexts/table';

import { Container, Logo, Title } from './styles';

export const Splash = () => {
	const navigate = useNavigate();
	const { table } = useTableVerification();

	useEffect(() => {
		setTimeout(() => {
			navigateToScanQrCode();
		}, 3000);
	}, []);

	const navigateToScanQrCode = () => {
		if (table) {
			navigate('/dashboard');
		}
		navigate('/scanQrCode');
	};

	return (
		<Container>
			<Logo src={LogoSnapIt} />
			<Title>Easy as eating</Title>
		</Container>
	);
};
