import styled from 'styled-components';

interface IBurgerProps {
	isOpen: boolean;
}

export const Container = styled.div<IBurgerProps>`
	width: 100vw;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	position: fixed;
	top: 0;
	left: 0;
	transform: ${({ isOpen }) =>
		isOpen ? 'translateX(0)' : 'translateX(-100%)'};
	transition: transform 0.5s ease-in;
	z-index: 999;
`;

export const Menu = styled.div`
	width: 90%;
	height: 100%;
	background-color: ${({ theme }) => theme.colors.white};
	padding: 1rem 1rem 2.5rem 1rem;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	overflow: scroll !important;
`;

export const TopWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const HeaderMenu = styled.div`
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: space-between;
`;

export const GreetingsText = styled.span`
	font-size: 2rem;
	color: ${({ theme }) => theme.colors.black};
	line-height: 3rem;
`;

export const HamburgerIcon = styled.img`
	width: 2.5rem;
	height: 2.5rem;
	cursor: pointer;
`;

export const ItemsList = styled.ul`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	margin-top: 3.5rem;
`;

export const Item = styled.li`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.4rem 0.7rem 0.4rem 0;
`;

export const ItemIcon = styled.img`
	width: 2rem;
	height: 2rem;
`;

export const ItemName = styled.h3`
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.black};
	margin-left: 1rem;
`;

export const Footer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const SnapItLogo = styled.img`
	width: 11rem;
	height: 2rem;
	margin-left: -2.5rem;
`;

export const Disclaimer = styled.h1`
	font-size: 1.2rem;
`;
