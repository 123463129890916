import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 0.5rem;
`;

export const WelcomeText = styled.h1`
	font-size: 1.4rem;
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 0.8rem;
`;

export const CarouselItemContainer = styled.div`
	width: 100%;
	position: relative;
`;

export const CarouselBackgroundImage = styled.img`
	width: 100%;
	height: auto;
	border-radius: 0.5rem;
`;

export const ContentWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	position: absolute;
	top: 0;
	padding: 1rem;
	z-index: 2;
`;

export const SetFoodIcon = styled.div`
	width: 2.2rem;
	height: 2.2rem;
	border-radius: 2rem;
	background-color: black;
`;

export const FooterWrapper = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 0.2rem;
	justify-content: space-between;
	align-items: flex-end;
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled.h1`
	font-size: 0.9rem;
	color: ${({ theme }) => theme.colors.white};
	margin-bottom: 0.2rem;
`;

export const DishName = styled.span`
	font-size: 1.3rem;
	color: ${({ theme }) => theme.colors.white};
`;

export const Circle = styled.div`
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.colors.black};
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const AwardIcon = styled.img`
	width: 1.7rem;
	height: 1.7rem;
`;
