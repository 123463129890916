import { createContext, useContext, useMemo, useState } from 'react';

import {
	cartContextProps,
	cartContextProviderProps,
	cartProps,
} from 'types/cart';
import { Product } from 'types/products';

export const cartContext = createContext({} as cartContextProps);

export const CartContextProvider = ({ children }: cartContextProviderProps) => {
	const [cart, setCart] = useState<cartProps[]>([]);

	const isOnCart = (product: Product) => {
		const productInCartPosition = cart.findIndex((item) => {
			return item.product.id === product.id;
		});

		return productInCartPosition >= 0;
	};

	const addToCart = (product: Product) => {
		if (cart.length === 0) {
			setCart([
				{
					product,
					quantity: 1,
				},
			]);
			return;
		}

		const productInCartPosition = cart.findIndex((item) => {
			return item.product.id === product.id;
		});

		if (productInCartPosition >= 0) {
			var newCart = cart;
			newCart[productInCartPosition] = {
				product,
				quantity: newCart[productInCartPosition].quantity + 1,
			};
			setCart(newCart);
		} else {
			setCart([...cart, { product, quantity: 1 }]);
		}
	};

	const removeFromCart = (product: Product) => {
		const productInCartPosition = cart.findIndex((item) => {
			return item.product.id === product.id;
		});

		if (productInCartPosition >= 0) {
			if (cart[productInCartPosition].quantity > 1) {
				var newCart = cart;
				newCart[productInCartPosition] = {
					product,
					quantity: newCart[productInCartPosition].quantity - 1,
				};
				setCart(newCart);
			} else {
				const filteredCart = cart.filter(
					(item) => item.product.id !== product.id
				);
				setCart(filteredCart);
			}
		}
	};

	const totalValue = useMemo(() => {
		return cart
			.reduce((total, item) => {
				return total + item.product.price * item.quantity;
			}, 0)
			.toFixed(2);
	}, [cart, addToCart, removeFromCart]);

	const isCartEmpty = useMemo(() => {
		return cart.length === 0;
	}, [cart, removeFromCart]);

	const totalUnits = useMemo(() => {
		return cart.reduce((total, item) => {
			return total + item.quantity;
		}, 0);
	}, [cart, addToCart, removeFromCart]);

	return (
		<cartContext.Provider
			value={{
				cart,
				addToCart,
				removeFromCart,
				totalValue,
				totalUnits,
				isCartEmpty,
				isOnCart,
			}}
		>
			{children}
		</cartContext.Provider>
	);
};

export const useCart = () => {
	const context = useContext(cartContext);

	return context;
};
