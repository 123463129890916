import Hamburger from 'assets/hamburger.svg';

const items = [
	{
		icon: Hamburger,
		name: 'Your profile',
		link: '/dashboard',
	},
	{
		icon: Hamburger,
		name: 'Change location',
		link: '/dashboard',
	},
	{
		icon: Hamburger,
		name: 'Report an issue',
		link: '/dashboard',
	},
	{
		icon: Hamburger,
		name: 'About snap it',
		link: '/dashboard',
	},
];

export default items;
