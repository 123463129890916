interface HamburgerMenuProps {
	size: number;
	color: string;
}

export const SearchIcon = ({ size, color }: HamburgerMenuProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox={`0 0 ${size} ${size}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M28 28L22.2091 22.2091M22.2091 22.2091C24.1394 20.2789 25.3333 17.6122 25.3333 14.6667C25.3333 8.77563 20.5577 4 14.6667 4C8.77563 4 4 8.77563 4 14.6667C4 20.5577 8.77563 25.3333 14.6667 25.3333C17.6122 25.3333 20.2789 24.1394 22.2091 22.2091Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
export default SearchIcon;
