import styled, { keyframes } from 'styled-components';

const grow = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const growImage = keyframes`
    0% {
      width: 0;
      opacity: 0;
    }
    100% {
      width: 15rem;
      opacity: 1;
    }
`;

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.bright_pink};
`;

export const Logo = styled.img`
	width: 15rem;
	height: auto;
	animation: ${growImage} 2s ease-in-out;
`;

export const Title = styled.h1`
	font-size: 1.4rem;
	font-family: 'NeueMontreal';
	line-height: 1.5rem;
	color: ${({ theme }) => theme.colors.white};
	animation: ${grow} 2s ease-in-out;
`;
