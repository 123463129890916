import Starter from 'assets/starter.png';

import {
	Container,
	ItemImage,
	ItemImageWrapper,
	ItemText,
	MenuShorcutItem,
	MenuShortcutItemWrapper,
	MenuShortcutText,
} from './styles';

export const FancyGrid = ({ data }: any) => {
	const { overview } = data;
	const { categories } = overview;
	return (
		<Container>
			<MenuShortcutText>What do you fancy tonight?</MenuShortcutText>
			<MenuShortcutItemWrapper>
				{categories.map((item: any) => {
					return (
						<MenuShorcutItem>
							<ItemImageWrapper>
								<ItemImage src={Starter} />
							</ItemImageWrapper>
							<ItemText>{item.name}</ItemText>
						</MenuShorcutItem>
					);
				})}
			</MenuShortcutItemWrapper>
		</Container>
	);
};
