import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	padding: 0.8rem;
	background-color: ${({ theme }) => theme.colors.bright_pink};
	margin-top: 1.5rem;
	border-radius: 0.5rem;
`;

export const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Title = styled.h1`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.colors.white};
	margin-bottom: 0.5rem;
`;

export const Subtitle = styled.span`
	font-size: 0.9rem;
	color: ${({ theme }) => theme.colors.white};
`;

export const Image = styled.img`
	width: 4rem;
	height: 4rem;
	margin-left: 3rem;
`;
