import { io } from 'socket.io-client';

import { useEffect } from 'react';

import { Footer } from 'components/footer';
import { Header } from 'components/header';

import theme from 'styles/theme';

import { GoToCheckout } from './components/goToCheckout';
import { LastMinuteCombinations } from './components/last-minute-combinations';
import { OrderItems } from './components/order-items';
import { Container } from './styles';

export const Cart = () => {
	return (
		<Container>
			<Header
				backgroundColor={theme.colors.light_grey}
				isFixed
				iconColor={theme.colors.black}
				logoColor={theme.colors.bright_pink}
				logoSize={40}
			/>
			<OrderItems />
			<LastMinuteCombinations />
			<GoToCheckout />
			<Footer />
		</Container>
	);
};
