import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 1rem;
	margin-bottom: 1rem;
`;

export const Title = styled.h1`
	font-size: 2rem;
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 2rem;
`;

export const ItemsContainer = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-bottom: 1.5rem;
`;
