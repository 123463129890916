import Clock from 'assets/clock.svg';
import Star from 'assets/star.svg';
import TwoPeople from 'assets/twopeople.svg';

import { Header as ContentHeader } from 'components/header';

import theme from 'styles/theme';

import {
	BackgroundImage,
	BackgroundImageOpacity,
	BottomContent,
	Container,
	ContentContainer,
	Description,
	Icon,
	IconContainer,
	IconText,
	Title,
} from './styles';

export const Header = ({ data }: any) => {
	const { overview } = data;
	const { name } = overview;
	return (
		<Container>
			<ContentHeader
				backgroundColor="rgba(0,0,0,0)"
				isFixed={false}
				iconColor={theme.colors.white}
				logoColor={theme.colors.white}
				logoSize={40}
			/>
			<ContentContainer>
				<Title>Welcome to {name}</Title>
				<Description>Contemporany cucine & café</Description>
				<BottomContent>
					<IconContainer>
						<Icon src={Star} />
						<IconText>3.7</IconText>
					</IconContainer>
					<IconContainer>
						<Icon src={Clock} />
						<IconText>10:00 – 22:00</IconText>
					</IconContainer>
					<IconContainer>
						<Icon src={TwoPeople} />
						<IconText>Fine dinning & cafe</IconText>
					</IconContainer>
				</BottomContent>
			</ContentContainer>
			<BackgroundImage src="https://media-cdn.tripadvisor.com/media/photo-s/0a/12/fd/3e/fatma-frieda.jpg" />
			<BackgroundImageOpacity />
		</Container>
	);
};
