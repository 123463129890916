import axios from 'axios';

const api = axios.create({
	baseURL:
		process.env.NODE_ENV === 'production'
			? process.env.BACKEND_URL
			: 'http://localhost:3333',
});

export default api;
