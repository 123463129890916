import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 2rem;
`;

export const Title = styled.h1`
	font-size: 1.2rem;
`;
