import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100vw;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.white};
	padding-bottom: 6rem;
	padding-top: 5rem;
`;
