import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useState,
} from 'react';

import { Table } from 'types/table';

import api from '../services/api';

interface TableVerificationContextState {
	table?: Table;
	verifyTable: (id: string) => Promise<Table>;
	leaveTable: () => void;
	removeTableFromLocalStorage: () => void;
}

interface VerifyTableProps {
	children: ReactNode;
}

const TableVerificationContext = createContext<TableVerificationContextState>(
	{} as TableVerificationContextState
);

export const TableVerificationProvider = ({ children }: VerifyTableProps) => {
	const [data, setData] = useState<Table | undefined>(() => {
		const table = localStorage.getItem('@Snapit:table');

		if (table) {
			return JSON.parse(table);
		}

		return undefined;
	});

	const removeTableFromLocalStorage = () => {
		localStorage.removeItem('@Snapit:table');
	};

	const verifyTable = useCallback(async (id: string) => {
		const cachedTable = localStorage.getItem('@Snapit:table');

		if (cachedTable) {
			return cachedTable;
		}
		const response = await api.get(`establishments/tables/${id}`);

		const table = response.data;

		if (!response.data) {
			return undefined;
		}

		localStorage.setItem('@Snapit:table', JSON.stringify(table));

		setData(table);

		return table;
	}, []);

	const leaveTable = useCallback(() => {
		localStorage.removeItem('@Snapit:table');

		setData(undefined);
	}, []);

	return (
		<TableVerificationContext.Provider
			value={{
				table: data,
				verifyTable,
				leaveTable,
				removeTableFromLocalStorage,
			}}
		>
			{children}
		</TableVerificationContext.Provider>
	);
};

export function useTableVerification(): TableVerificationContextState {
	const context = useContext(TableVerificationContext);

	if (!context) {
		throw new Error(
			'tableVerification must be used within an tableVerificationProvider'
		);
	}

	return context;
}
