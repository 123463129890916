import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-top: 1.5rem;
`;

export const Title = styled.h1`
	font-size: 1.5rem;
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 0.5rem;
`;

export const Subtitle = styled.h2`
	font-size: 0.9rem;
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 0.5rem;
`;

export const Description = styled.h2`
	font-size: 0.8rem;
	color: ${({ theme }) => theme.colors.black};
`;

export const ContentWrapper = styled.img`
	width: 100%;
	height: 15rem;
	border-radius: 0.5rem;
	margin-top: 1rem;
`;
