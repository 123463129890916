import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 1.5rem;
`;

export const HotDealsText = styled.h1`
	font-size: 1.4rem;
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 0.8rem;
`;

export const HotDealsCarouselItem = styled.div`
	width: 8rem;
	height: 9.9rem;
	border-radius: 0.5rem;
	margin-right: 1rem;
	position: relative;
	display: flex;
	flex-direction: column;
`;

export const ItemBackgroundImage = styled.img`
	width: 100%;
	height: 65%;
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
`;

export const Footer = styled.div`
	width: 100%;
	height: 35%;
	background-color: ${({ theme }) => theme.colors.light_grey};
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding: 0 0.5rem;
`;

export const FooterTitle = styled.span`
	font-size: 0.8rem;
`;

export const FooterSubtitle = styled.span`
	font-size: 0.7rem;
`;
