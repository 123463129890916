import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 90%;
	padding: 1rem;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 7rem;
	box-shadow: 0px 4px 12px -8px #0000004d;
`;

export const LeftContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const ItemsAmount = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	background-color: ${({ theme }) => theme.colors.bright_pink};
	margin-right: 1rem;
`;

export const ItemsAmountText = styled.span`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.colors.white};
`;

export const Text = styled.span`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.colors.black};
`;

export const Price = styled.span`
	font-size: 1.2rem;
	font-family: 'NeueMontrealBold';
	color: ${({ theme }) => theme.colors.black};
`;
