import { Footer } from 'components/footer';

import { useEstablishmentOverview } from 'hooks/useEstablishmentOverview';

import { About } from './components/about';
import { FancyGrid } from './components/fancyGrid';
import { Header } from './components/header';
import HotDeals from './components/hotDeals';
import InviteGift from './components/inviteGift';
import { Promotions } from './components/promotions';
import { Container, ContentWrapper } from './styles';

export const Dashboard = () => {
	const { data } = useEstablishmentOverview();
	return (
		<Container>
			{data && (
				<>
					<Header data={data} />
					<ContentWrapper>
						<FancyGrid data={data} />

						<HotDeals />

						<Promotions />

						<About data={data.overview.about} />

						<InviteGift />
					</ContentWrapper>
				</>
			)}

			<Footer />
		</Container>
	);
};
