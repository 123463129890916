import styled from 'styled-components';

export const Container = styled.div`
	height: auto;
	background-color: ${({ theme }) => theme.colors.white};
`;

export const ContentWrapper = styled.div`
	display: flex;
	padding: 1rem 1rem 6rem 1rem;
	flex-direction: column;
`;
