import InviteGiftImage from 'assets/invite-gift.png';

import { Container, Image, Subtitle, TextWrapper, Title } from './styles';

const InviteGift = () => {
	return (
		<Container>
			<TextWrapper>
				<Title>Give €10 and get €10</Title>
				<Subtitle>
					Invite a friend and you’ll both get snap it credits to use on your
					next restaurant.
				</Subtitle>
			</TextWrapper>
			<Image src={InviteGiftImage} />
		</Container>
	);
};

export default InviteGift;
