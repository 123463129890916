import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-top: 1.5rem;
`;

export const MenuShortcutText = styled.h1`
	font-size: 1.4rem;
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 0.8rem;
`;

export const MenuShortcutItemWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

export const MenuShorcutItem = styled.div`
	width: 20.1%;
	height: 6rem;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 1rem;
`;

export const ItemImageWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.bright_grey};
	border-radius: 0.5rem;
	padding: 0.8rem 0;
`;

export const ItemImage = styled.img`
	width: 2.5rem;
	height: 3rem;
`;

export const ItemText = styled.span`
	font-size: 0.9rem;
	margin-top: 0.3rem;
`;
