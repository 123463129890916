import styled from 'styled-components';

export const Container = styled.div`
	width: 100vw;
	height: 100vh;
	background-color: ${({ theme }) => theme.colors.background};
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const Title = styled.p`
	font-size: 2rem;
	color: white;
`;

export const NameField = styled.input`
	font-size: 1rem;
	color: black;
	margin-top: 1rem;
`;

export const GotoMenuButton = styled.button`
	width: 8rem;
	height: 3rem;
	border-radius: 0.5rem;
	margin-top: 1rem;
`;
