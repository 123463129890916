import HotDealImage from 'assets/hot-deal.jpeg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
	Container,
	Footer,
	FooterSubtitle,
	FooterTitle,
	HotDealsCarouselItem,
	HotDealsText,
	ItemBackgroundImage,
} from './styles';

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 3, // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 4,
		slidesToSlide: 2, // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 3,
		slidesToSlide: 1, // optional, default to 1.
	},
};

const HotDeals = () => {
	return (
		<Container>
			<HotDealsText>Hot deals</HotDealsText>
			<Carousel
				responsive={responsive}
				showDots={false}
				autoPlay
				autoPlaySpeed={3000}
				arrows={false}
				infinite
			>
				<HotDealsCarouselItem>
					<ItemBackgroundImage src={HotDealImage} />
					<Footer>
						<FooterTitle>Carrot & eggplant</FooterTitle>
						<FooterSubtitle>Plant base 🌱</FooterSubtitle>
					</Footer>
				</HotDealsCarouselItem>
				<HotDealsCarouselItem>
					<ItemBackgroundImage src="https://www.macheesmo.com/wp-content/uploads/2018/10/Everything-Avocado-Toast.jpg" />
					<Footer>
						<FooterTitle>Avocado Toast</FooterTitle>
						<FooterSubtitle>Plant base 🌱</FooterSubtitle>
					</Footer>
				</HotDealsCarouselItem>
				<HotDealsCarouselItem>
					<ItemBackgroundImage src="https://media.healthyfood.com/wp-content/uploads/2019/07/Creamy-pumpkin-soup-1024x638.jpg" />
					<Footer>
						<FooterTitle>Pumpkin soup</FooterTitle>
						<FooterSubtitle>Starter</FooterSubtitle>
					</Footer>
				</HotDealsCarouselItem>
				<HotDealsCarouselItem>
					<ItemBackgroundImage src={HotDealImage} />
					<Footer>
						<FooterTitle>Carrot & eggplant</FooterTitle>
						<FooterSubtitle>Plant base 🌱</FooterSubtitle>
					</Footer>
				</HotDealsCarouselItem>
			</Carousel>
		</Container>
	);
};

export default HotDeals;
