import { ReactComponent as CartIcon } from 'assets/cart.svg';
import { ReactComponent as MenuIcon } from 'assets/footer-menu.svg';
import { ReactComponent as HomeIcon } from 'assets/house.svg';
import styled from 'styled-components';

interface ISVGProps {
	selected: boolean;
}

export const Container = styled.div`
	width: 100%;
	padding: 0.4rem;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	bottom: 0;
	border-top: 1px solid ${({ theme }) => theme.colors.light_grey};
`;

export const IconWrapper = styled.div`
	display: flex;
	height: 3.5rem;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	padding: 0.3rem 0.6rem;
	margin: 0 2rem;
	cursor: pointer;
`;

export const Home = styled(HomeIcon)<ISVGProps>`
	path {
		stroke: ${({ selected, theme }) =>
			selected ? theme.colors.bright_pink : theme.colors.black};
	}
`;

export const Cart = styled(CartIcon)<ISVGProps>`
	path {
		stroke: ${({ selected, theme }) =>
			selected ? theme.colors.bright_pink : theme.colors.black};
		fill: ${({ selected, theme }) =>
			selected ? theme.colors.bright_pink : theme.colors.black};
	}
`;

export const Menu = styled(MenuIcon)<ISVGProps>`
	path {
		stroke: ${({ selected, theme }) =>
			selected ? theme.colors.bright_pink : theme.colors.black};
	}
`;

export const IconName = styled.span`
	font-size: 0.8rem;
`;
