import { Category } from 'types/category';

import {
	CategoryContainer,
	CategoryText,
	CategoryWrapper,
	Container,
	Title,
} from './styles';

const data = ['🥟 Starters', '🍝 Main', '🍨 Desert', '🦪 Seasonal'];

interface CategoriesProps {
	categories: Category[];
	handleChangeCategory: (category: Category) => void;
	selectedCategory: string;
}

export const Categories = ({
	categories,
	selectedCategory,
	handleChangeCategory,
}: CategoriesProps) => {
	return (
		<Container>
			<Title>Starters</Title>
			<CategoryContainer>
				{categories.map((category: Category) => {
					const isSelectedCategory =
						selectedCategory === category.name;
					return (
						<CategoryWrapper
							isSelected={isSelectedCategory}
							onClick={() => handleChangeCategory(category)}
						>
							<CategoryText isSelected={isSelectedCategory}>
								{category.name}
							</CategoryText>
						</CategoryWrapper>
					);
				})}
			</CategoryContainer>
		</Container>
	);
};
