import {
	Container,
	ContentWrapper,
	Description,
	Subtitle,
	Title,
} from './styles';

export const About = ({ data }: any) => {
	return (
		<Container>
			<Title>About the restaurant</Title>
			<Subtitle>
				Some info about the amazing place you are eating today
			</Subtitle>
			<Description>{data}</Description>
			<ContentWrapper src="https://www.ignant.com/wp-content/uploads/2019/06/ignant-travel-restaurant-frea-01-e1560961026846-2880x1899.jpg" />
		</Container>
	);
};
