import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 1rem;
`;

export const Title = styled.h1`
	font-size: 2rem;
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 2rem;
`;

export const Item = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1.5rem;
`;

export const LeftContent = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ItemsAmount = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.5rem 1.5rem;
	border: 1px solid ${({ theme }) => theme.colors.bright_pink};
	border-radius: 0.25rem;
	margin-right: 1rem;
`;

export const ItemsAmountText = styled.span`
	font-size: 1.2rem;
	color: ${({ theme }) => theme.colors.black};
`;

export const ItemInfo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const ItemName = styled.span`
	font-size: 1rem;
	font-family: 'NeueMontrealBold';
	color: ${({ theme }) => theme.colors.black};
`;

export const ItemPrice = styled.span`
	font-size: 0.9rem;
	color: ${({ theme }) => theme.colors.black};
`;

export const ItemImage = styled.img`
	width: 3rem;
	height: 3rem;
	border-radius: 0.25rem;
`;
