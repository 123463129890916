import styled from 'styled-components';

export const ItemCard = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0.45rem 0;
`;

export const ItemTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ItemName = styled.span`
	font-size: 1rem;
	font-family: 'NeueMontrealBold';
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 0.4rem;
`;

export const ItemIngredients = styled.span`
	font-size: 0.9rem;
	color: ${({ theme }) => theme.colors.black};
	margin-bottom: 0.4rem;
`;

export const ItemPrice = styled.span`
	font-size: 0.9rem;
	font-family: 'NeueMontrealBold';
	color: ${({ theme }) => theme.colors.black};
`;

export const ItemImage = styled.img`
	width: 6rem;
	height: 6rem;
	border-radius: 0.5rem;
	margin-right: 0.1rem;
`;
