import { io } from 'socket.io-client';

import { useCart } from 'contexts/cart';
import { useTableVerification } from 'contexts/table';

import {
	Container,
	ItemsAmount,
	ItemsAmountText,
	LeftContent,
	Price,
	Text,
} from './styles';

const socket = io('http://localhost:3333');

export const GoToCheckout = () => {
	const { totalValue, cart } = useCart();
	const { table } = useTableVerification();

	const handleCreateOrder = () => {
		socket.emit('create_order', {
			establishment_id: table.establishment_id,
			customer_name: 'Lucas',
			comment: 'No comments',
			table_number: table.table_number,
			total_price: totalValue,
			products: cart,
		});
	};
	return (
		<Container onClick={handleCreateOrder}>
			<LeftContent>
				<ItemsAmount>
					<ItemsAmountText>3</ItemsAmountText>
				</ItemsAmount>
				<Text>Go to checkout</Text>
			</LeftContent>
			<Price>€ {totalValue}</Price>
		</Container>
	);
};
