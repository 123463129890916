import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100vw;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.white};
	padding: 6rem 0;
	align-items: center;
`;
