import { HomeIcon } from 'assets/icons/home';
import { useLocation, useNavigate } from 'react-router-dom';

import theme from 'styles/theme';

import { Cart, Container, Home, IconName, IconWrapper, Menu } from './styles';

export const Footer = () => {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const selected = (screen: string) => {
		if (pathname.includes(screen)) {
			return true;
		}
		return false;
	};

	const handleChangeScreen = (screen: string) => {
		navigate(screen);
	};

	return (
		<Container>
			<IconWrapper onClick={() => handleChangeScreen('/dashboard')}>
				<HomeIcon
					size={27}
					color={
						selected('dashboard')
							? theme.colors.bright_pink
							: theme.colors.black
					}
					fill={selected('dashboard') ? theme.colors.bright_pink : 'none'}
				/>
				<IconName>Home</IconName>
			</IconWrapper>

			<IconWrapper onClick={() => handleChangeScreen('/menu')}>
				<Menu selected={selected('menu')} />
				<IconName>Menu</IconName>
			</IconWrapper>

			<IconWrapper onClick={() => handleChangeScreen('/cart')}>
				<Cart selected={selected('cart')} />
				<IconName>Cart</IconName>
			</IconWrapper>
		</Container>
	);
};
